<template>
  <div style="max-height: 500px">
    <TreeTable :data="departmentsList"
               :columns="columns"
               :loading="loadingStaus"
               ref="treeTable"
               @handleSelectionChange="handleSelectionChange"
               highlight-current-row
               height="500">
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
    </TreeTable>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TreeTable from "@/components/TreeTable";

export default {
  name: "DepartmentsChoice",
  components: {
    TreeTable
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  data() {
    return {
      loadingStaus: true,
      columns: [
        {
          title: '部门名称',
          value: 'name'
        },
        {
          title: '部门标识(来自钉钉)',
          value: 'dpt_id',
          width: 200
        },
      ],
      // 表格列表数据
      departmentsList: [],
      multipleSelection: []
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$emit('handleSelectionChange', val)
    },
    async getDepartmentsList() {
      let {list} = await this.$api.getDepartmentsList()
      this.departmentsList = list
      this.loadingStaus = false
    },
  },
  mounted() {
    this.getDepartmentsList()
  }
}
</script>

<style scoped>

</style>